<template>
  <div class="app">
    <CRow>
      <CCol sm="4">
        <h4 class="text-uppercase">Feedbacks</h4>
      </CCol>

      <CCol sm="8"> </CCol>
    </CRow>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CRow class="pb-3">
            <CCol sm="10">
              <form v-on:submit.prevent="filterFeedback(filter)">
                <CRow>
                  <CCol sm="2" class="pr-0">
                    <el-date-picker
                        required
                        class="w-100 p-0"
                        v-model="filter.start_date"
                        type="date"
                        placeholder="Enter start date"
                        :format="$datePickerFormat"
                        value-format="yyyy-MM-dd"
                    />
                  </CCol>
                  <CCol sm="2" class="pr-0">
                    <el-date-picker
                        required
                        class="w-100 p-0"
                        v-model="filter.end_date"
                        type="date"
                        placeholder="Enter end date"
                        :format="$datePickerFormat"
                        value-format="yyyy-MM-dd"
                    />
                  </CCol>
                  <CCol sm="2" class="pr-0">
                    <select
                      name="rating"
                      v-model="filter.rating"
                      class="form-control"
                    >
                      <option value="">Select Rating</option>
                      <option v-for="item in 5" :key="item" :value="item">
                        {{ item }}
                      </option>
                    </select>
                  </CCol>
                  <CCol sm="2">
                    <button class="btn btn-success w-100">Search</button>
                  </CCol>
                </CRow>
              </form>
            </CCol>
            <CCol sm="2">
              <button
                v-if="checkUsrPermissions(['Feedback_all', 'Feedback_export'])"
                type="button"
                @click="exportReport()"
                class="btn btn-primary float-right"
              >
                Export
              </button>
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="12">
              <div class="table-responsive">
                <table class="table table-borderless">
                  <thead>
                    <tr class="text-center">
                      <th scope="col">SL</th>
                      <th scope="col">Consumer's Name</th>
                      <th scope="col">Rating</th>
                      <th scope="col">Comment</th>
                      <th scope="col">Skipped?</th>
                      <th scope="col">App Name</th>
                      <th scope="col">Created At</th>
                    </tr>
                  </thead>
                  <tbody v-if="feedbacks.length">
                    <tr
                      class="text-center"
                      v-for="(feedback, index) in feedbacks"
                      :key="feedback.id"
                    >
                      <td scope="row">{{ meta.from + index }}</td>
                      <td scope="row">
                        {{
                          feedback.customer.salutation +
                            " " +
                            feedback.customer.first_name +
                            " " +
                            feedback.customer.last_name
                        }}
                      </td>
                      <td scope="row">{{ feedback.rating }}</td>
                      <td scope="row">{{ feedback.comment }}</td>
                      <td scope="row">
                        {{ feedback.is_skipped ? "Yes" : "No" }}
                      </td>
                      <td scope="row">{{ feedback.app_name }}</td>
                      <td scope="row">{{ _dateTimeFormat(feedback.created_at) }}</td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="6">
                        <h5 class="text-center">Data Not Available.</h5>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </CCol>
          </CRow>
          <CPagination
            v-if="feedbacks.length"
            align="center"
            :pages="rows"
            :active-page.sync="filter.currentPage"
            @update:activePage="handlePageChange"
          />
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data: () => {
    return {
      filter: {
        currentPage: 1,
        rating: "",
        start_date: "",
        end_date: "",
        is_skipped: "",
      },
    };
  },
  methods: {
    handlePageChange(value) {
      this.filter.currentPage = value;
      this.$router.replace({ name: "CustomerFeedbacks", query: this.filter });
      this.$store.dispatch("Feedback/getFeedbacks", this.filter);
    },
    setSearchParams() {
      this.filter.currentPage = this.$route.query.currentPage
        ? parseInt(this.$route.query.currentPage)
        : 1;
      this.filter.rating = this.$route.query.rating
        ? parseInt(this.$route.query.rating)
        : "";
      this.filter.start_date = this.$route.query.start_date
        ? this.$route.query.start_date
        : "";
      this.filter.end_date = this.$route.query.end_date
        ? this.$route.query.end_date
        : "";
    },
    filterFeedback(search) {
      if (search) {
        search.currentPage = 1;
        if( this.$route.fullPath !== this.$route.path +'?' + new URLSearchParams(search).toString()) {
          this.$router.replace({ name: "CustomerFeedbacks", query: search });
          this.$store.dispatch("Feedback/getFeedbacks", search);
        }
      }
    },
    exportReport() {
      this.$store.dispatch("Feedback/exportFeedbacks", this.filter);
    },
  },
  computed: {
    ...mapGetters("Feedback", ["feedbacks", "rows", "perPage", "meta"])
  },
  mounted() {
    this.setSearchParams();
    this.$store.dispatch("Feedback/getFeedbacks", this.filter);
  },
};
</script>
<style scoped>
.card {
    border: none;
    padding: 15px 15px 0;
}
tbody:before {
  content: "@";
  display: block;
  /* line-height: 30px; */
  text-indent: -99999px;
}
.hide-footer >>> footer {
  display: none;
}
.custom-close-button >>> .close {
  color: aliceblue;
}
</style>
<style lang="scss" scoped>
div {
  h4 {
    font-size: 15px;
    font-weight: bold !important;
    color: #000;
  }
  .table {
    font-size: 14px;

    thead {
      tr {
        th {
          background: #f8f9fa;
          color: #000;
        }
      }
    }
    tbody {
      font-size: 13px;
      tr {
        color: #000;
        td {
          padding: 0.75rem 0 0.75rem 0.75rem;
          button {
            font-size: 13px;
          }
          a {
            color: black;
          }
          span {
            cursor: pointer;
          }
        }
      }
    }
  }
}
.role {
  font-size: 11px;
  margin: 4px 4px;
  font-weight: 100;
}
</style>
